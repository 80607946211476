// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faFacebook       as fabFaFacebook       } from "@fortawesome/free-brands-svg-icons/faFacebook";
import { faInstagram      as fabFaInstagram      } from "@fortawesome/free-brands-svg-icons/faInstagram";

import { faBars           as fasFaBars           } from "@fortawesome/pro-solid-svg-icons/faBars";
import { faCaretDown      as fasFaCaretDown      } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { faCaretLeft      as fasFaCaretLeft      } from "@fortawesome/pro-solid-svg-icons/faCaretLeft";
import { faCaretRight     as fasFaCaretRight     } from "@fortawesome/pro-solid-svg-icons/faCaretRight";
import { faQuestionCircle as fasFaQuestionCircle } from "@fortawesome/pro-solid-svg-icons/faQuestionCircle";
import { faSearch         as fasFaSearch         } from "@fortawesome/pro-solid-svg-icons/faSearch";

/**
 * Add brand icons
 */
library.add(fabFaFacebook, fabFaInstagram);

/**
 * Add solid icons
 */
library.add(fasFaBars, fasFaCaretDown, fasFaCaretLeft, fasFaCaretRight, fasFaQuestionCircle, fasFaSearch);

/**
 * Watch the DOM to insert icons
 */
dom.watch();
